import React from 'react'
import { Row, Col, Button, Label, Card, CardBody, CardTitle, CardText, Table, CardHeader } from 'reactstrap'
import DatePicker from "react-datepicker";
import Skeleton from 'react-loading-skeleton'
import { ResponsiveSankey } from '@nivo/sankey'
import { toast } from 'react-toastify'
import equal from 'fast-deep-equal'
import SwatchMonsterApi from '../../services/SwatchMonsterApi';

import { objToParam } from '../../utilities/objToParam'

function toPercent(float) {
  let num = (float * 100).toFixed(2)
  return `${num}%`
}

class FlowBreakdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = { buckets: [], isLoaded: false }
  }
  componentDidMount = () => {
    this.setData()
  }
  componentDidUpdate = (prevProps) => {
    if(!equal(this.props.swatchRequests, prevProps.swatchRequests)) {
      this.setData();
    }
  }
  setData = () => {
    let buckets = {} // buckets breaks down into Owner => Worker => Closer
    this.props.swatchRequests.forEach((swatchRequest) => {
      // Setup the owner/worker/closer attributes
      let owner = swatchRequest.attributes.lead_owner
      let worker = swatchRequest.attributes.worked_by
      if (worker === null) { worker = 'Not' }
      let closer = swatchRequest.attributes.lead_closer
      if (closer === null) { closer = 'Not' }

      // Setup objects if none are present.
      if (buckets[owner] === undefined) { buckets[owner] = {name: owner, count: 0, workers: {}} }
      if (buckets[owner].workers[worker] === undefined) { buckets[owner].workers[worker] = {name: worker, count: 0, closers: {}} }
      if (buckets[owner].workers[worker].closers[closer] === undefined) { buckets[owner].workers[worker].closers[closer] = {name: closer, count: 0}}

      // Now do the math, add/subtract update totals as needed on the object.
      buckets[owner].count += 1
      buckets[owner].workers[worker].count += 1
      buckets[owner].workers[worker].closers[closer].count += 1
    })
    // Flatten the objects down into arrays for easier traversal. Working your way from top down.
    // Owner flatten
    buckets = Object.keys(buckets).reduce(function (r, k) {
      return r.concat(buckets[k]);
    }, []);
    // Worker flatten
    buckets.forEach((worker) => {
      worker.workers = Object.keys(worker.workers).reduce(function (r, k) {
        return r.concat(worker.workers[k]);
      }, []);
    })
    // Closer flatten
    buckets.forEach((worker) => {
      worker.workers.forEach((sWorker) => {
        sWorker.closers = Object.keys(sWorker.closers).reduce(function (r, k) {
          return r.concat(sWorker.closers[k]);
        }, []);
      })
    })
    // Perform arithemtic to calc rates inclusive of totals
    let srCount = this.props.swatchRequests.length
    buckets.forEach((bucket) => {
      bucket.closeRate = bucket.count / srCount
      bucket.workers.forEach((worker) => {
        worker.closeRate = worker.count / bucket.count
        worker.closeRateOverall = worker.count / srCount
        worker.closers.forEach((closer) => {
          closer.closeRate = closer.count / worker.count
          closer.closeRateOverall = closer.count / srCount
        })
      })
    })
    this.setState({ buckets: buckets, isLoaded: false })
  }
  render = () => {
    let els = []
    this.state.buckets.forEach((bucket) => {
      let workerEls = []
      bucket.workers.forEach((worker) => {
        let closerEls = []
        let noCloseEl = []
        let closeEls = []
        let sumClose = 0
        let sumClosePercent = 0
        worker.closers.forEach((closer) => {
          if (closer.name === 'Not') {
            noCloseEl.push(<Col md={6}><Card><CardHeader>{closer.name} Closed - {closer.count} ({toPercent(closer.closeRate)})</CardHeader></Card></Col>)
          } else {
            sumClose += closer.count
            sumClosePercent += closer.closeRate
            closeEls.push(<CardText>{closer.name} Closed - {closer.count} ({toPercent(closer.closeRate)})</CardText>)
          }
        })
      let baseEl = <Row>{noCloseEl}<Col md={6}><Card><CardHeader>Closed - {sumClose} ({toPercent(sumClosePercent)})</CardHeader><CardBody>{closeEls}</CardBody></Card></Col></Row>
        closerEls.push(baseEl)
        workerEls.push(<Card className="mb-2"><CardHeader>{worker.name} Worked - {worker.count} ({toPercent(worker.closeRate)})</CardHeader><CardBody>{closerEls}</CardBody></Card>)
      })
      els.push(<Card className="mb-3"><CardHeader>{bucket.name} Open - {bucket.count} ({toPercent(bucket.closeRate)})</CardHeader><CardBody>{workerEls}</CardBody></Card>)
    })
    return(
      <Row>
        <Col>{els}</Col>
      </Row>
    )
  }
}
class WorkRateCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { workRates: [], isLoaded: false }
  }
  componentDidMount() {
    this.setData()
  }
  componentDidUpdate(prevProps) {
    if(!equal(this.props.swatchRequests, prevProps.swatchRequests))
    {
      this.setData();
    }
  } 
  setData = () => {
    let workRates = {}
    this.props.swatchRequests.forEach((swatchRequest) => {
      let worker = swatchRequest.attributes.worked_by
      if (worker === null) { worker = 'Not Worked' }
      if (workRates[worker] === undefined) { workRates[worker] = {worker: worker, opener: {}, closer: {}, total: 0} }
      let opener = swatchRequest.attributes.lead_owner
      let closer = swatchRequest.attributes.lead_closer
      if (closer === null) { closer = 'Not Closed' }
      if (workRates[worker].opener[opener] === undefined) { workRates[worker].opener[opener] = {opener: opener, count: 0}}
      if (workRates[worker].closer[closer] === undefined) { workRates[worker].closer[closer] = {closer: closer, count: 0}}
      workRates[worker].opener[opener].count += 1
      workRates[worker].closer[closer].count += 1
      workRates[worker].total += 1
    })
    workRates = Object.keys(workRates).reduce(function (r, k) {
      return r.concat(workRates[k]);
    }, []);
    workRates.forEach((entity) => {
      entity.closer = Object.keys(entity.closer).reduce(function (r, k) {
        return r.concat(entity.closer[k]);
      }, []);
      entity.opener = Object.keys(entity.opener).reduce(function (r, k) {
        return r.concat(entity.opener[k]);
      }, []);
      entity.closer.forEach((closer) => {
        closer.closeRate = closer.count / entity.total
      })
      entity.opener.forEach((opener) => {
        opener.closeRate = opener.count / entity.total
      })
    })
    this.setState({workRates: workRates, isLoaded: true})
  }
  render() {
    let els = []
    if (this.state.isLoaded) {
      this.state.workRates.forEach((workRate) => {
        let closer = workRate.closer.sort((a,b) => a.closeRate - b.closeRate).reverse().map((closer) => {
          return <tr key={closer.closer}><td>{closer.closer}</td><td>{(closer.closeRate * 100).toFixed(2)}%</td></tr>
        })
        let opener = workRate.opener.sort((a,b) => a.closeRate - b.closeRate).reverse().map((opener) => {
          return <tr key={opener.opener}><td>{opener.opener}</td><td>{(opener.closeRate * 100).toFixed(2)}%</td></tr>
        })
        els.push(<Row key={workRate.worker}>
          <Col>
            <h4>{workRate.worker}</h4>
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>By Opener</CardTitle>
                    <Table><tbody>{opener}</tbody></Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle>By Closer</CardTitle>
                    <Table><tbody>{closer}</tbody></Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>)
      })
    }
    return(
      <Row>
        <Col>
          {els}
        </Col>
      </Row>
    )
  }
}

class CloseRateCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = { closeRatesByOwner: [], closeRatesByWorker: [], closeRatesByCloser: [], isLoaded: false }
  }
  componentDidMount() {
    this.setCloseRates()
  }
    componentDidUpdate(prevProps) {
      if(!equal(this.props.swatchRequests, prevProps.swatchRequests)) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      {
        this.setCloseRates();
      }
    } 
  setCloseRates = () => {
    let closeRatesByOwner = {}
    let closeRatesByWorker = {}
    let closeRatesByCloser = {}
    this.props.swatchRequests.forEach((swatchRequest) => {
      let owner = swatchRequest.attributes.lead_owner
      let worker = swatchRequest.attributes.worked_by
      if (worker === null) { worker = 'No One'}
      let closer = swatchRequest.attributes.lead_closer
      if (closer === null) { closer = 'Not Closed'}
      if (closeRatesByOwner[owner] === undefined) {
        closeRatesByOwner[owner] = {
          lead_owner: swatchRequest.attributes.lead_owner,
          won: 0,
          lost: 0
        }
      }
      if (closeRatesByWorker[worker] === undefined) {
        closeRatesByWorker[worker] = {
          worked_by: worker,
          won: 0,
          lost: 0
        }
      }
      if (closeRatesByCloser[closer] === undefined) {
        closeRatesByCloser[closer] = {
          closed_by: closer,
          won: 0,
          lost: 0
        }
      }
      if (swatchRequest.attributes.lead_closer === null) {
        closeRatesByOwner[owner].lost += 1
        if (closer === 'Not Closed') {
          closeRatesByCloser[closer].won += 1
        } else {
          closeRatesByCloser[closer].lost += 1
        }
        closeRatesByWorker[worker].lost += 1
      } else {
        closeRatesByOwner[owner].won += 1
        if (closer === 'Not Closed') {
          closeRatesByCloser[closer].lost += 1
        } else {
          closeRatesByCloser[closer].won += 1
        }
        closeRatesByWorker[worker].won += 1
      }
    })
    let flatOwner = Object.keys(closeRatesByOwner).reduce(function (r, k) {
      return r.concat(closeRatesByOwner[k]);
    }, []);
    let flatWorker = Object.keys(closeRatesByWorker).reduce(function (r, k) {
      return r.concat(closeRatesByWorker[k]);
    }, []);
    let flatCloser = Object.keys(closeRatesByCloser).reduce(function (r, k) {
      return r.concat(closeRatesByCloser[k]);
    }, []);
    flatOwner.forEach((entity) => {
      entity.total = entity.lost + entity.won
      entity.closeRate = entity.won / entity.total
    })
    flatCloser.forEach((entity) => {
      entity.total = entity.lost + entity.won
      entity.closeRate = entity.won / this.props.swatchRequests.length
    })
    flatWorker.forEach((entity) => {
      entity.total = entity.lost + entity.won
      entity.closeRate = entity.won / entity.total
    })
    this.setState({closeRatesByOwner: flatOwner, closeRatesByWorker: flatWorker, closeRatesByCloser: flatCloser, isLoaded: true})
  }
  render() {
    let owner = <Skeleton />
    let worker = <Skeleton />
    let closer = <Skeleton />
    if (this.state.isLoaded) {
      owner = this.state.closeRatesByOwner.sort((a,b) => a.closeRate - b.closeRate).reverse().map((owner) => {
        return <tr key={owner.lead_owner}><td>{owner.lead_owner}</td><td>{(owner.closeRate * 100).toFixed(2)}%</td></tr>
      })
      worker = this.state.closeRatesByWorker.sort((a,b) => a.closeRate - b.closeRate).reverse().map((owner) => {
        return <tr key={worker.worked_by}><td>{owner.worked_by}</td><td>{(owner.closeRate * 100).toFixed(2)}%</td></tr>
      })
      closer = this.state.closeRatesByCloser.sort((a,b) => a.closeRate - b.closeRate).reverse().map((owner) => {
        return <tr key={owner.closed_by}><td>{owner.closed_by}</td><td>{(owner.closeRate * 100).toFixed(2)}%</td></tr>
      })
    }
    return(
      <Row>
        <Col md={4}>
          <Card>
            <CardBody>
              <CardTitle>By Owner</CardTitle>
              <Table>
                <tbody>{owner}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <CardTitle>By Worker</CardTitle>
              <Table>
                <tbody>{worker}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <CardBody>
              <CardTitle>By Closer</CardTitle>
              <Table>
                <tbody>{closer}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default class CloseRate extends React.Component {
  constructor(props) {
    super(props)
    let currentDate = new Date()
    let date = new Date(new Date().setDate(new Date().getDate() - 30));
    this.state = {startDate: date, endDate: currentDate, swatchRequests: [], chartData: {}, isLoaded: false}
  }
  componentDidMount = () => {
    this.fetchSwatchRequests()
  }
  update = () => {
    this.fetchSwatchRequests()
  }
  handleStartDateChange = (e) => {
    this.setState({startDate: e})
  }
  handleEndDateChange = (e) => {
    this.setState({endDate: e})
  }
  fetchSwatchRequests = () => {
    this.setState({loading: true})
    let api = new SwatchMonsterApi(this.props.user)
    let params = {
      q: {
        created_at_gt: this.state.startDate.toISOString(),
        created_at_lt: this.state.endDate.toISOString()
      }
    }
    api.getSwatchRequests(objToParam(params),(status, headers, response) => {
      if (status === 200) {
        this.setState({swatchRequests: response.data})
        this.setData()
      } else {
        toast('')
      }
    })
  }
  setData = () => {
    let swatchRequests = this.state.swatchRequests
    let nodes = [{id: 'Not Closed'}, {id: 'Not Worked'}]
    let links = []
    swatchRequests.forEach((swatchRequest) => {
      // Adding in a node for the lead owner's open
      if (nodes.find(x => x.id === `${swatchRequest.attributes.lead_owner} Open`) === undefined) { nodes.push({id: `${swatchRequest.attributes.lead_owner} Open`})}
      // Adding in a node for the lead closer.
      if (swatchRequest.attributes.lead_closer !== null && nodes.find(x => x.id === `${swatchRequest.attributes.lead_closer} Closed`) === undefined) { 
        nodes.push({id: `${swatchRequest.attributes.lead_closer} Closed`})
      }
      // Add a node for the lead worker.
      if (swatchRequest.attributes.worked === true && nodes.find(x => x.id === `${swatchRequest.attributes.worked_by} Worked`) === undefined) { nodes.push({id: `${swatchRequest.attributes.worked_by} Worked`}) }

      // First determine if worked or not worked and build that node.
      let worker = 'Not Worked'
      if ((swatchRequest.attributes.worked === true && swatchRequest.attributes.worked_by !== null)) { worker = `${swatchRequest.attributes.worked_by} Worked`}
      // Now build a link if one doesn't exist.
      let workedLink = links.find(x => x.source === `${swatchRequest.attributes.lead_owner} Open` && x.target === worker)
      if (workedLink === undefined) {
        links.push({source: `${swatchRequest.attributes.lead_owner} Open`, target: worker, value: 1})
      } else {
        workedLink.value += 1
      }
      // Now build the link from the worker to the closer
      let closer = 'Not Closed'
      if (swatchRequest.attributes.lead_closer !== null) { closer = `${swatchRequest.attributes.lead_closer} Closed`}
      let foundLink = links.find(x => x.source === worker && x.target === closer)
      if (foundLink === undefined) {
        links.push({source: worker, target: closer, value: 1})
      } else {
        foundLink.value += 1
      }
    })
    this.setState({chartData: {nodes: nodes, links: links}, isLoaded: true})
  }
  render() {
    let data = {
      "nodes": [
        {
          "id": "Ben"
        },
        {
          "id": "Web Open"
        },
        {
          "id": "Not Worked"
        }, {
          "id": "Not Closed"
        }
      ],
      "links": [
        {
          "source": "Web Open",
          "target": "Not Worked",
          "value": 36
        },
        {
          "source": "Not Worked",
          "target": "Ben",
          "value": 16
        }
      ]
    }
    if (this.state.isLoaded) {
      data = this.state.chartData
    }
    return(
      <Row>
        <Col>
          <Row>
            <Col><p>Summarizes close rates for swatch requests by time period and by person</p></Col>
          </Row>
          <Row>
            <Col>
              <Label>Start Date</Label>
              <DatePicker selected={this.state.startDate} onChange={this.handleStartDateChange} className="form-control"></DatePicker>
            </Col>
            <Col>
              <Label>End Date</Label>
              <DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange} className="form-control"></DatePicker>
            </Col>
            <Col>
              <Button onClick={this.update}>Update</Button>
            </Col>
          </Row>
          <Row>
            <Col>
            <div style={{height: 1200}}>
            <ResponsiveSankey
              data={data}
              margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
              align="justify"
              colors={{ scheme: 'category10' }}
              nodeOpacity={1}
              nodeThickness={18}
              nodeInnerPadding={3}
              nodeSpacing={24}
              nodeBorderWidth={0}
              nodeBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
              linkOpacity={0.5}
              linkHoverOthersOpacity={0.1}
              enableLinkGradient={true}
              labelPadding={16}
              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
              animate={true}
              motionStiffness={140}
              motionDamping={13}
          />
          </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FlowBreakdown key={this.state.swatchRequests.length} swatchRequests={this.state.swatchRequests}></FlowBreakdown>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h2>Close Rate</h2>
                </Col>
              </Row>
              <Row>
              <Col>
                <CloseRateCard key={this.state.swatchRequests.length} swatchRequests={this.state.swatchRequests} />
              </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Work Rate</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <WorkRateCard key={this.state.swatchRequests.length + 1} swatchRequests={this.state.swatchRequests}></WorkRateCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}