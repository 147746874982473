import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Row, Col, Table, Button } from 'reactstrap'
import SwatchMonsterApi from '../../services/SwatchMonsterApi'
import { toast } from 'react-toastify'

export default class SwatchMapping extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isLoaded: false, swatchMappings: []}
  }
  componentDidMount = () => {
    this.fetchMappings()
  }
  fetchMappings = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.getSwatchMappings((status, headers, response) => {
      if (status === 200) {
        this.setState({isLoaded: true, swatchMappings: response.data})
      } else {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    })
  }
  render() {
    let listItems = []
    if (this.state.isLoaded) {
      listItems = this.state.swatchMappings.map((swatchMapping) => {
      return (
        <tr>
          <td>{swatchMapping.attributes.term}<br /> <small>By {swatchMapping.attributes.manufacturer_name}</small></td>
          <td>=></td>
          <td>{swatchMapping.attributes.swatch_name}<br /> <small>By {swatchMapping.attributes.swatch_manufacturer}</small></td>
          <td>
            <Button size="sm">Edit</Button>{' '}
            <Button size="sm">Delete</Button>
          </td>
        </tr>
      )
      })
    } else {
      listItems = [...Array(30)].map((e, i) => {return <tr key={i}><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td></tr>})
    }
    return(
      <Row>
        <Col>
          <Row>
            <Col>
              <p>Current swatch mappings in the system. A term is routed to a specific swatch to smooth out bad API data.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                {listItems}
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

