import React from 'react'
import { connect } from 'react-redux'
import {ButtonDropdown, 
  DropdownToggle, DropdownMenu, DropdownItem, 
  Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Button,
  Form, FormGroup, Label, Input
} from 'reactstrap';
import { toast } from 'react-toastify'

import SwatchMonsterApi from '../services/SwatchMonsterApi'

class SwatchAdjustmentDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, modalOpen: false, adjustmentType: null }
  }
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }
  toggleModal = (type) => {
    this.setState({ adjustmentType: type, modalOpen: !this.state.modalOpen})
  }
  handleCreate = (adjustment) => {
    this.setState({modalOpen: false})
    if (this.props.handleCreate !== undefined) {
      this.props.handleCreate(adjustment)
    }
  }
  render() {
    return(
      <div>
      <ButtonDropdown toggle={this.toggle} isOpen={this.state.isOpen}>
        <DropdownToggle caret size="sm">
          Create Adjustment
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => this.toggleModal('Adjustment::NewStock')}>Restock</DropdownItem>
          <DropdownItem onClick={() => this.toggleModal('Adjustment::ManualIncrease')}>Manual Increase</DropdownItem>
          <DropdownItem onClick={() => this.toggleModal('Adjustment::ManualDecrease')}>Manual Decrease</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <SwatchAdjustmentModal 
        isOpen={this.state.modalOpen} 
        toggle={this.toggleModal} 
        swatchId={this.props.swatchId} 
        type={this.state.adjustmentType} 
        handleCreate={this.handleCreate}
        user={this.props.user} />
      </div>
    )
  }
}

class SwatchAdjustmentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {amount: 0, source: ''}
  }
  handleChange = (e) => {
    let state = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }
  handleSubmit = (e) => {
    e.preventDefault()
    let api = new SwatchMonsterApi(this.props.user)
    api.createAdjustment(
      {adjustment: {
      swatch_id: this.props.swatchId,
      type: this.props.type,
      amount: this.state.amount,
      source: this.state.source
      }},
    (status, headers, response) => {
      if (status === 201) {
        this.props.handleCreate(response.data)
      } else {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    }
    )
  }
  render() {
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <Form onSubmit={this.handleSubmit}>
          <ModalHeader toggle={this.props.toggle}>Create Adjustment</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Amount</Label>
                  <Input type={'number'} onChange={this.handleChange} name="amount" value={this.state.amount}></Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Source</Label>
                  <Input type={'text'} onChange={this.handleChange} name="source" value={this.state.source}></Input>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type={'submit'}>Create</Button>{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  { }
)(SwatchAdjustmentDropdown);