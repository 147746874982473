import React from 'react'
import { connect } from 'react-redux'
import { Container, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, Collapse, Button } from 'reactstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { signOut } from '../redux/actions'
import logo from '../swatch_monster.png';
import Count from './Count'
import Reports from './Reports'
import Swatches from './Swatches'
import Swatch from './Swatch'
import SignIn from './SignIn'

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {collapsed: false, user: null}
  }
  toggleNavbar = () => {
    this.setState({collapsed: !this.state.collapsed})
  }
  signOut = () => {
    this.props.signOut()
  }
  render() {
    return(
      <Container>
        <Router>
          <Navbar light expand="md">
            <NavbarBrand href="/"><img src={logo} alt="Swatch Monster" className="brand-logo" /></NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} />
            <Collapse isOpen={this.state.collapsed} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link to="/reports" className="nav-link" onClick={this.toggleNavbar}>Reports</Link>
                </NavItem>
                <NavItem>
                  <Link to="/swatches" className="nav-link" onClick={this.toggleNavbar}>Swatches</Link>
                </NavItem>
                <NavItem>
                  <Link to="/count" className="nav-link" onClick={this.toggleNavbar}>Count</Link>
                </NavItem>
                <NavItem>
                  <Button onClick={this.signOut}>Sign Out</Button>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
          <hr></hr>
          <Switch>
            <Route path="/sign_in" component={SignIn} />
            <Route path="/count" component={Count} />
            <Route path="/reports" component={Reports} />
            <Route path="/swatches/:id" component={Swatch} />
            <Route path="/">
              <Swatches />
            </Route>
          </Switch>
        </Router>
      </Container>
    )
  }
}

export default connect(
  null,
  { signOut }
)(Main);