import React from 'react'
import { connect } from 'react-redux'

import { signIn } from '../redux/actions'
import logo from '../swatch_monster.png';
import { Container, Row, Col, Label, Input, FormGroup, Button, Form } from 'reactstrap'

class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = { username: '', password: '' }
  }
  isOpen = () => {
    return false
  }
  handleChange = (e) => {
    let state = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }
  signIn = (e) => {
    e.preventDefault()
      fetch(`${process.env.REACT_APP_BASE_API_PATH}/auth/sign_in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password
        })
      })
      .then(response => response.json().then(data => ({response: response, status: response.status, body: data})))
      .then((result) => {
        if (result.status === 200) {
        let user = result.body
        user.accessToken = result.response.headers.get('access-token')
        user.client = result.response.headers.get('client')
        user.expiry = result.response.headers.get('expiry')
        user.uid = result.response.headers.get('uid')
        this.props.signIn(user)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }
  render() {
    return(
      <Container>
        <Form onSubmit={this.signIn}>
          <Row>
            <Col className="text-center">
              <img src={logo} alt="Swatch Monster"></img>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Email</Label>
                <Input type={'text'} onChange={this.handleChange} name="email"></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Password</Label>
                <Input type={'password'} onChange={this.handleChange} name="password"></Input>
              </FormGroup>
            </Col>
            </Row>
          <Row>
            <Col>
              <FormGroup>
              <Button block>Sign In</Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  {signIn}
)(SignIn);