import React from 'react'
import { Badge } from 'reactstrap'

export class AdjustmentBadge extends React.Component {
  render() {
    if (this.props.type === 'Adjustment::ManualIncrease') {
      return <Badge color={'success'}>{this.props.amount}</Badge>
    } else {
      return <Badge color={'danger'}>-{this.props.amount}</Badge>
    }
  }
}

export class SavedBadge extends React.Component {
  render() {
    if (this.props.status === 'unsaved') {
      return <Badge color={'warning'}>Unsaved</Badge>
    } else if (this.props.status === 'saving') {
      return <Badge>Saving</Badge>
    } else if (this.props.status === 'saved') {
      return <Badge color={'success'}>Saved</Badge>
    } else if (this.props.status === 'failed') {
      return <Badge color={'danger'}>Failed</Badge>
    }
  }
}

export class CountBadge extends React.Component {
  render() {
    if (this.props.amount > 0) {
      return <Badge color={'success'}>+{this.props.amount}</Badge>
    } else if (this.props.amount < 0) {
      return <Badge color={'danger'}>{this.props.amount}</Badge>
    } else {
      return <Badge>Spot On</Badge>
    }
  }
}

export class ActiveBadge extends React.Component {
  render() {
    if (this.props.active) {
      return <Badge color="success">Active</Badge>
    } else {
      return <Badge color="danger">Inactive</Badge>
    }
  }
}