import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux'
import { Container, Row, Col, Card, Button, Input, Modal, Form, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import { AdjustmentBadge, SavedBadge, CountBadge } from './Badges'
import SwatchMonsterApi from '../services/SwatchMonsterApi';

class Count extends React.Component {
  constructor(props) {
    super(props)
    this.state = {guided: null}
  }
  setGuided = (shouldGuide) => {
    this.setState({guided: shouldGuide})
  }
  render() {
    let component = <GuidedCount user={this.props.user}></GuidedCount>
    return(
      <div>
        {component}
      </div>
    )
  }
}

class GuidedCount extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isLoaded: false, swatches: [], reviewModalOpen: false, changeOrders: []}
  }
  componentDidMount() {
    this.getSwatches()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.user.access_token !== this.props.user.access_token) {
      this.getSwatches();
    }
  }
  getSwatches = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.getNeedsCountingReport((status, headers, response) => {
      if (status === 200) {
        this.setState({isLoaded: true, swatches: response.data})
      }
    })
  }
  countChange = (swatch, count) => {
    let changeOrders = this.state.changeOrders
    let foundSwatch = this.state.swatches.find(el => el.id === swatch.id)
    if (foundSwatch !== undefined) {
      let changeOrder = null
      let changeOrderIndex = changeOrders.findIndex(el => el.swatch.id === swatch.id)
      if (changeOrderIndex === -1) { 
        changeOrder = {swatch: swatch, type: '', amount: 0, saveState: 'unsaved'} 
      } else {
        changeOrder = changeOrders[changeOrderIndex]
      }
      if (count > foundSwatch.attributes.current_level) {
        changeOrder.type = 'Adjustment::ManualIncrease'
        changeOrder.amount = count - foundSwatch.attributes.current_level
      } else {
        changeOrder.type = 'Adjustment::ManualDecrease'
        changeOrder.amount = foundSwatch.attributes.current_level - count
      }
      if (changeOrderIndex === -1) {
        if (count !== foundSwatch.current_level) {
          changeOrders.push(changeOrder)
        }
      } else {
        if (changeOrder.amount !== 0) {
          changeOrders[changeOrderIndex] = changeOrder
        } else {
          changeOrders.splice(changeOrderIndex, 1)
        }
      }
      this.setState({changeOrders: changeOrders})
    }
  }
  toggleReviewModal = () => {
    this.setState({reviewModalOpen: !this.state.reviewModalOpen})
  }
  updateChangeOrder = (changeOrder, payload) => {
    let changeOrders = this.state.changeOrders
    let index = changeOrders.findIndex((el) => el.swatch.id === changeOrder.swatch.id)
    changeOrders[index] = Object.assign(changeOrders[index], payload)
    this.setState({changeOrders: changeOrders})
  }
  render() {
    const listItems = this.state.swatches.map((swatch) => 
      <CountRow swatch={swatch} countChange={this.countChange}></CountRow>
    )
    if (this.state.isLoaded) {
    return(
      <Container>
        <Row>
          <Col md={9}>{listItems}</Col>
          <Col md={3}>
            <Card body>
                <Button onClick={this.toggleReviewModal}>Save Count</Button>
                <ReviewModal isOpen={this.state.reviewModalOpen} 
                  toggle={this.toggleReviewModal} 
                  changeOrders={this.state.changeOrders} 
                  updateChangeOrder={this.updateChangeOrder}
                  user={this.props.user} />
            </Card>
          </Col>
        </Row>
      </Container>
    )
    } else {
      return <Skeleton count={30}></Skeleton>
    }
  }
}

class CountRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {amount: null}
  }
  countChange = (e) => {
    this.setState({amount: e.target.value - this.props.swatch.attributes.current_level})
    this.props.countChange(this.props.swatch, e.target.value)
  }
  countIcon = () => {
    return <CountBadge amount={this.state.amount}></CountBadge>
  }
  render() {
    return(
      <Row className="mb-2">
        <Col>{this.props.swatch.attributes.name}<br /><small>By {this.props.swatch.attributes.manufacturer_name}</small></Col>
        <Col>{this.props.swatch.attributes.current_level}</Col>
        <Col><Input type={'number'} onChange={this.countChange}></Input></Col>
        <Col>{this.countIcon()}</Col>
      </Row>
    )
  }
}

class ReviewModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {savingCount: false}
  }
  saveCounts = () => {
    let api = new SwatchMonsterApi(this.props.user)
    this.setState({savingCount: true})
    this.props.changeOrders.forEach((changeOrder) => {
      this.props.updateChangeOrder(changeOrder, {saveState: 'saving'})
      api.createAdjustment({adjustment: {
        from_count: true,
        amount: changeOrder.amount,
        type: changeOrder.type,
        swatch_id: changeOrder.swatch.id,
        source: 'Manual Count'
      }}, (status, headers, response) => {
        if (status === 201) {
          this.props.updateChangeOrder(changeOrder, {saveState: 'saved'})
        } else {
          this.props.updateChangeOrder(changeOrder, {saveState: 'failed'})
        }
      })
    })
    this.setState({savingCount: false})
  }
  render() {
    const listItems = this.props.changeOrders.map((changeOrder) => 
      <tr>
        <td>{changeOrder.swatch.attributes.name}</td>
        <td>{changeOrder.type}</td>
        <td><AdjustmentBadge amount={changeOrder.amount} type={changeOrder.type}></AdjustmentBadge></td>
        <td><SavedBadge status={changeOrder.saveState}></SavedBadge></td>
      </tr>
    )
    let saveButton = ''
    if (this.state.savingCount) {
      saveButton = <Button disabled>Saving Count</Button>
    } else {
      saveButton = <Button color="primary" onClick={this.saveCounts}>Save Count</Button>
    }
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size={'lg'}>
        <Form>
          <ModalHeader toggle={this.props.toggle}>Review Count Changes</ModalHeader>
          <ModalBody>
            <Table>
              {listItems}
            </Table>
          </ModalBody>
          <ModalFooter>
            {saveButton}{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  { }
)(Count);