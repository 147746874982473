import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Nav, Navbar, NavItem, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap'
import CloseRate from './reports/CloseRate'
import MissedOrders from './reports/MissedOrders'
import RequestVolume from './reports/RequestVolume'
import SwatchMappings from './reports/SwatchMappings'
import SwatchMonsterApi from '../services/SwatchMonsterApi'

class NeedsOrderingReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {isLoaded: false, swatches: []}
  }
  componentDidMount() {
    this.loadSwatches()
  }
  loadSwatches = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.getNeedsOrderingReport((status, headers, response) => { 
      if (status === 200) {
        this.setState({isLoaded: true, swatches: response.data})
      } else {
        // Error
      }
    })
  }
  render() {
    const listItems = this.state.swatches.map((swatch) => 
      <tr><td>{swatch.attributes.name}</td></tr>
    )
    return(
      <table>
        {listItems}
      </table>
    )
  }
}

class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {activeReport: 'closeRate', isOpen: false}
  }
  reports = {
    'needsOrdering': {klass: NeedsOrderingReport, name: 'Order Sheet'},
    'missedOrders' : {klass: MissedOrders, name: 'Missed Orders'},
    'closeRate'    : {klass: CloseRate, name: 'Close Rate'},
    'swatchMappings': {klass: SwatchMappings, name: 'Swatch Mappings'},
    'requestVolume' : {klass: RequestVolume, name: 'Volume'}
  }
  setReport = (report) => {
    this.setState({activeReport: report, isOpen: false})
  }
  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }
  render() {
    const ActiveReport = this.reports[this.state.activeReport].klass
    return(
      <Row>
        <Col>
          <Row>
            <Col>
              <Navbar expand="md" light>
                <NavbarBrand>{this.reports[this.state.activeReport].name}</NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                  <NavItem><Button color="link" onClick={() => this.setReport('closeRate')} block>Close Rate</Button></NavItem>
                  <NavItem><Button color="link" onClick={() => this.setReport('requestVolume')} block>Volume</Button></NavItem>
                  <NavItem><Button color="link" onClick={() => this.setReport('missedOrders')} block>Missed Orders</Button></NavItem>
                  <NavItem><Button color="link" onClick={() => this.setReport('swatchMappings')} block>Swatch Mappings</Button></NavItem>
                  <NavItem><Button color="link" onClick={() => this.setReport('needsOrdering')} block>Order Sheet</Button></NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
              <hr></hr>
            </Col>
          </Row>
          <Row>
            <Col>
              <ActiveReport user={this.props.user}></ActiveReport>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  { }
)(Reports);