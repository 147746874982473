import axios from 'axios'
import { CancelToken } from 'axios'

class SwatchMonsterApi {
  constructor(authState) {
    let service = axios.create({
      headers: {
        'access-token': authState.accessToken,
        'client': authState.client,
        'uid': authState.uid,
        'expiry': authState.expiry,
        'token-type': authState.tokenType
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess = (response) => {
    return response;
  }

  handleError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          return error;
        case 401:
          return error.response;
        case 404:
          break;
        default:
          return error.response
      }
    } else {
      return 'bad'
    }
  }

  get(path, callback) {
    return this.service.get(path).then(
      (response) => callback(response.status, response.headers, response.data)
    );
  }

  cancellableGet(path, cancel, callback) {
    return this.service.get(path, {cancelToken: new CancelToken(c => (cancel.exec = c))}).then(
      (response) => callback(response.status, response.headers, response.data)
    );
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.headers, response.data));
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.headers, response.data));
  }

  delete(path, callback) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json'
    }).then((response) => callback(response.status, response.headers, response.data))
  }

  signIn(email, password, callback) {
    this.post(`${process.env.REACT_APP_BASE_API_PATH}/auth/sign_in`, {email: email, password: password}, callback)
  }

  getSwatches(searchParams, cancel, callback) {
    this.cancellableGet(`${process.env.REACT_APP_BASE_API_PATH}/swatches?${searchParams}`, cancel, callback)
  }

  getSwatch(id, callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/swatches/${id}`, callback)
  }

  createSwatch(swatchParams, callback) {
    this.post(`${process.env.REACT_APP_BASE_API_PATH}/swatches`, swatchParams, callback)
  }

  updateSwatch(id, swatchParams, callback) {
    this.put(`${process.env.REACT_APP_BASE_API_PATH}/swatches/${id}`, swatchParams, callback)
  }

  getManufacturers(callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/manufacturers`, callback)
  }

  getAdjustments(swatchId, callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/adjustments/?swatch_id=${swatchId}`, callback)
  }

  createAdjustment(adjustmentParams, callback) {
    this.post(`${process.env.REACT_APP_BASE_API_PATH}/adjustments`, adjustmentParams, callback)
  }

  getNeedsCountingReport(callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/swatches?needs_counting=true`, callback)
  }

  getNeedsOrderingReport(callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/swatches?needs_ordering=true`, callback)
  }

  getMissedOrders(searchParams, callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/missed_orders?${searchParams}`, callback)
  }

  updateMissedOrder(id, params, callback) {
    this.put(`${process.env.REACT_APP_BASE_API_PATH}/missed_orders/${id}`, params, callback)
  }

  getSwatchMappings(callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/swatch_mappings`, callback)
  }

  createSwatchMapping(params, callback) {
    this.post(`${process.env.REACT_APP_BASE_API_PATH}/swatch_mappings`, params, callback)
  }

  getSwatchRequests(params, callback) {
    this.get(`${process.env.REACT_APP_BASE_API_PATH}/swatch_requests?${params}`, callback)
  }
}

export default SwatchMonsterApi

