import React from 'react'
import Pagination from 'react-js-pagination';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, ListGroup, ListGroupItem, ButtonGroup } from 'reactstrap'
import { objToParam } from '../../utilities/objToParam'
import SwatchMonsterApi from '../../services/SwatchMonsterApi'

class MapSwatchToTerm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {modalOpen: false, isLoading: false, search: '', swatches: [], missedOrder: null, openGetRequests: []}
  }
  searchSwatches = () => {
    let openGetRequests = this.state.openGetRequests
    openGetRequests.forEach((cancel) => {
      cancel.exec()
    })
    let cancel = { exec: null } 
    openGetRequests.push(cancel)
    let api = new SwatchMonsterApi(this.props.user)
    api.getSwatches(objToParam({q: {name_cont: this.state.search}}), cancel,(status, headers, response) => { 
      if (status === 200) {
        this.setState({isLoaded: true, swatches: response.data})
      } else {
        if (status !== undefined) {
          toast("💩 Oh ****, something bad happened. API Error")
        }
      }
    })
  }
  handleChange = (e) => {
    this.setState({search: e.target.value}, () => {
      this.searchSwatches()
    })
  }
  map = (id) => {
    let api = new SwatchMonsterApi(this.props.user)
    api.createSwatchMapping({swatch_mapping: {swatch_id: id, missed_order_id: this.props.missedOrder.id}}, (status, headers, response) => {
      if (status === 201) {
        toast("Swatch Mapping Created")
        this.props.handleMapping(this.props.missedOrder)
      } else {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    })
  }
  render() {
    const listItems = this.state.swatches.map((swatch) => 
      <ListGroupItem><Button color="link" onClick={() => this.map(swatch.id)}>{swatch.attributes.name} <small>By {swatch.attributes.manufacturer_name}</small></Button></ListGroupItem>
    )
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>{this.props.missedOrder.attributes.name}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Swatch</Label>
                <Input type={'text'} onChange={this.handleChange} name="swatch_id" value={this.state.swatch_id}></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup>
                {listItems}
              </ListGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

// This is the add swatch modal. It lets a user add a modal 
class AddSwatchWithTerm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {name: this.props.missedOrder.attributes.name, manufacturer_name: '', active: false, stocking: false, openGetRequests: [], swatches: []}
  }
  componentDidUpdate(prevProps) {
    if (prevProps.missedOrder.attributes.name !== this.props.missedOrder.attributes.name) {
      this.setState({name: this.props.missedOrder.attributes.name}, () => {this.searchSwatches()})
    }
  }
  handleChange = (e) => {
    let state = this.state
    state[e.target.name] = e.target.value
    this.setState(state, () => {this.searchSwatches()})
  }
  setActive = (bool) => {
    this.setState({active: bool})
  }
  setStocking = (bool) => {
    this.setState({stocking: bool})
  }
  searchSwatches = () => {
    let openGetRequests = this.state.openGetRequests
    openGetRequests.forEach((cancel) => {
      cancel.exec()
    })
    let cancel = { exec: null } 
    openGetRequests.push(cancel)
    let api = new SwatchMonsterApi(this.props.user)
    api.getSwatches(objToParam({q: {name_cont: this.state.name}}), cancel,(status, headers, response) => { 
      if (status === 200) {
        this.setState({isLoaded: true, swatches: response.data})
      } else {
        if (status !== undefined) {
          toast("💩 Oh ****, something bad happened. API Error")
        }
      }
    })
  }
  map = (id) => {
    let api = new SwatchMonsterApi(this.props.user)
    api.createSwatchMapping({swatch_mapping: {swatch_id: id, missed_order_id: this.props.missedOrder.id}}, (status, headers, response) => {
      if (status === 201) {
        toast("Swatch Mapping Created")
        this.props.handleMapping(this.props.missedOrder)
      } else {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    let api = new SwatchMonsterApi(this.props.user)
    api.createSwatch({
      swatch: {
        name: this.state.name,
        manufacturer_name: this.state.manufacturer_name,
        active: this.state.active,
        stocked: this.state.stocking
      }
    }, (status, headers, response) => {
      if (status === 201) {
        api.createSwatchMapping({swatch_mapping: {swatch_id: response.data.id, missed_order_id: this.props.missedOrder.id}}, (status, headers, response) => {
          if (status === 201) {
            toast("Swatch and Mapping Created")
            this.props.handleAdd(this.props.missedOrder)
          } else {
            toast("💩 Oh ****, something bad happened. API Error")
          }
        })
      } else {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    })
  }
  render() {
    const listItems = this.state.swatches.map((swatch) => 
      <ListGroupItem><Button color="link" onClick={() => this.map(swatch.id)}>{swatch.attributes.name} <small>By {swatch.attributes.manufacturer_name}</small></Button></ListGroupItem>
    )
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <Form onSubmit={this.handleSubmit}>
        <ModalHeader toggle={this.props.toggle}>Add Swatch: {this.props.missedOrder.attributes.name}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Name</Label>
                <Input type="text" onChange={this.handleChange} name="name" value={this.state.name} />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>MFG Name</Label>
                <Input type={'text'} onChange={this.handleChange} name="manufacturer_name" value={this.state.manufacturer_name}></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Active?</Label>
                <br />
                <ButtonGroup>
                  <Button onClick={() => this.setActive(true)} active={this.state.active} color="primary" outline>Yes</Button>
                  <Button onClick={() => this.setActive(false)} active={!this.state.active} color="primary" outline>No</Button>
                </ButtonGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Stocked?</Label>
                <br />
                <ButtonGroup>
                  <Button onClick={() => this.setStocking(true)} active={this.state.stocking} color="primary" outline>Yes</Button>
                  <Button onClick={() => this.setStocking(false)} active={!this.state.stocking} color="primary" outline>No</Button>
                </ButtonGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>{listItems}</Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit">Create Swatch</Button>
          <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
        </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

export default class MissedOrders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false, 
      modalOpen: false, 
      missedOrders: [], 
      swatches: [], 
      selectedMissedOrder: {attributes: {}}, 
      addModalOpen: false,
      currentPage: 1,
      totalEntries: 50
    }
  }
  componentDidMount() {
    this.loadData()
  }
  loadData = (page = 1) => {
    let api = new SwatchMonsterApi(this.props.user)
    let params = {
      page: page
    }
    api.getMissedOrders(objToParam(params), (status, headers, response) => {
      if (status === 200) {
        this.setState({isLoaded: true, missedOrders: response.data, currentPage: response.meta.current_page, totalEntries: response.meta.total_entries})
      }
    })
  }
  removeFromState = (id) => {
    let state = this.state
    let index = state.missedOrders.findIndex((el) => el.id === id)
    state.missedOrders.splice(index, 1)
    this.setState(state)
  }
  mark = (id, markType) => {
    let api = new SwatchMonsterApi(this.props.user)
    let payload = {}
    if (markType === 'fixed') {
      payload.fixed = true
    } else {
      payload.will_not_fix = true
    }
    api.updateMissedOrder(id, {missed_order: payload}, (status, headers, response) => {
      if (status === 200) {
        this.removeFromState(id)
        toast("Missed Order handled")
      } else {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    })
  }
  openModal = (id, type) => {
    let missedOrder = this.state.missedOrders.find((el) => el.id === id)
    this.setState({selectedMissedOrder: missedOrder})
    if (type === 'add') {
      this.toggleAddModal()
    } else {
      this.toggleModal()
    }
  }
  toggleModal = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  }
  handleMapping = (missedOrder) => {
    this.removeFromState(missedOrder.id)
    this.setState({modalOpen: false, addModalOpen: false})
  }
  toggleAddModal = () => {
    this.setState({addModalOpen: !this.state.addModalOpen})
  }
  handleAdd = (missedOrder) => {
    this.removeFromState(missedOrder.id)
    this.setState({modalOpen: false, addModalOpen: false})
  }
  handlePageChange = (page) => {
    this.loadData(page)
  }
  render() {
    let listItems = []
    if (this.state.isLoaded) {
      listItems = this.state.missedOrders.map((missedOrder) => 
      <tr>
        <td>{missedOrder.attributes.name}</td>
        <td>{missedOrder.attributes.manufacturer_name}</td>
        <td>{missedOrder.attributes.instance_count}</td>
        <td className="no-wrap text-center">
          <Button outline color="primary" onClick={() => this.openModal(missedOrder.id, 'add')} size="sm">Add Swatch</Button>{' '}
          <Button outline color="secondary" onClick={() => this.openModal(missedOrder.id, 'map')} size="sm">Map to Swatch</Button>{' '}
          <Button outline color="success" onClick={() => this.mark(missedOrder.id, 'fixed')} size="sm">Mark Fixed</Button>{' '}
          <Button outline color="danger" onClick={() => this.mark(missedOrder.id, 'will_not_fix')} size="sm">Will Not Fix</Button>
        </td>
      </tr>
      )
    } else {
      listItems = [...Array(30)].map((e, i) => {return <tr key={i}><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td></tr>})
    }
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <p>These are orders that hit the Swatch Monster API that could not be resolved to a known swatch.</p>
              <p>You can create a new swatch, map them to a known swatch, claim it was fixed in code, or label it as an unknown with no fix.</p>
              <Table>
                {listItems}
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <MapSwatchToTerm 
                isOpen={this.state.modalOpen} 
                toggle={this.toggleModal} 
                user={this.props.user} 
                missedOrder={this.state.selectedMissedOrder} 
                handleMapping={this.handleMapping} />
              <AddSwatchWithTerm 
                isOpen={this.state.addModalOpen}
                toggle={this.toggleAddModal}
                user={this.props.user}
                missedOrder={this.state.selectedMissedOrder}
                handleAdd={this.handleAdd}
                handleMapping={this.handleMapping} />
            </Col>
          </Row>
          <Row>
          <Col className="text-center">
            <Pagination
              activePage={this.state.currentPage}
              itemsCountPerPage={50}
              totalItemsCount={this.state.totalEntries}
              pageRangeDisplayed={5}
              itemClass="page-item"
              linkClass="page-link"
              onChange={this.handlePageChange}
            />
            {this.state.totalEntries} results
          </Col>
        </Row>
        </Col>
      </Row>
    )
  }
}