import React from 'react'
import { connect } from "react-redux";

import SignIn from './SignIn'
import Main from './Main'

class Auth extends React.Component {
  render() {
    if (this.props.user.accessToken === undefined) {
      return <SignIn></SignIn>
    } else {
      return <Main></Main>
    }
  }
}

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  { }
)(Auth);