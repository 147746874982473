// 3rd Party Imports
import React from 'react'
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton';
import { Row, Col, Button, ButtonGroup, Modal, Form, 
  ModalHeader, FormGroup, Label, Input, ModalBody, 
  ModalFooter, Table } from 'reactstrap';
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { objToParam } from '../utilities/objToParam'

// 1st Party Imports
import SwatchMonsterApi from '../services/SwatchMonsterApi'
import SwatchAdjustmentDropdown from './SwatchAdjustment'
import { SwatchActiveToggle, SwatchStockedToggle } from './Buttons'

class Swatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      modalOpen: false,
      swatches: [],
      search: '',
      currentPage: 1,
      totalEntries: 100,
      active: true,
      stocking: '',
      manufacturer: '',
      manufacturers: [],
      openGetRequests: [],
      per_page: 50
    }
  }
  componentDidMount() {
    this.fetchSwatches()
    this.fetchManufacturers()
  }
  fetchSwatches = () => {
    let openGetRequests = this.state.openGetRequests
    openGetRequests.forEach((cancel) => {
      cancel.exec()
    })
    let cancel = { exec: null } 
    openGetRequests.push(cancel)
    this.setState({openGetRequests: openGetRequests})
    let api = new SwatchMonsterApi(this.props.user)
    let params = {
      page: this.state.currentPage,
      q: {
        name_cont: this.state.search,
        active_true: this.state.active,
        stocked_true: this.state.stocking,
        manufacturer_id_eq: this.state.manufacturer
      }
    }
    api.getSwatches(objToParam(params), cancel, (status, headers, response) => { 
      if (status === 200) {
        this.setState({isLoaded: true, swatches: response.data, currentPage: response.meta.current_page, totalEntries: response.meta.total_entries})
      } else if (status !== undefined) {
        toast("💩 Oh ****, something bad happened. API Error")
      }
    })
  }
  fetchManufacturers = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.getManufacturers((status, headers, response) => {
      if (status === 200) {
        this.setState({manufacturers: response.data})
      } else {
        toast('API Error loading manufacturers')
      }
    })
  }
  search = (e) => {
    this.setState({search: e.target.value, currentPage: 1, isLoaded: false}, () => {
      this.fetchSwatches()
    })
  }
  toggleModal = () => {
    let state = this.state
    state.modalOpen = !this.state.modalOpen
    this.setState(state)
  }
  swatchAdded = (swatch) => {
    let state = this.state
    state.swatches.push(swatch)
    this.setState(state)
  }
  swatchUpdated = (swatch) => {
    let swatches = this.state.swatches
    let swatchIndex = swatches.findIndex((el) => el.id === swatch.id)
    swatches[swatchIndex] = swatch
    this.setState({swatches: swatches})
  }
  handlePageChange = (page) => {
    this.setState({currentPage: page, isLoaded: false}, () => {
      this.fetchSwatches()
    })
  }
  toggleActiveFilter = (state) => {
    this.setState({active: state, isLoaded: false}, () => {
      this.fetchSwatches()
    })
  }
  toggleStockingFilter = (state) => {
    this.setState({stocking: state, isLoaded: false}, () => {
      this.fetchSwatches()
    })
  }
  filterMfg = (e) => {
    this.setState({manufacturer: e.target.value, isLoaded: false}, () => {
      this.fetchSwatches()
    })
  }
  render() {
    let listItems = []
    if (this.state.isLoaded) {
      listItems = this.state.swatches.map((swatch) => 
        <tr key={swatch.id}>
          <td><span className="label">Name</span><span>{swatch.attributes.name}<br /><small>By {swatch.attributes.manufacturer_name}</small></span></td>
          <td><span className="label">Status</span><SwatchActiveToggle active={swatch.attributes.active} user={this.props.user} swatchId={swatch.id} handleChange={this.swatchUpdated} /></td>
          <td><span className="label">Current Level</span>{swatch.attributes.current_level}</td>
          <td><span className="label">Velocity</span>{swatch.attributes.velocity}/day</td>
          <td><span className="label">Days Left</span>{swatch.attributes.days_until_out} days left</td>
          <td><span className="label">Stocking</span><SwatchStockedToggle stocked={swatch.attributes.stocked} user={this.props.user} swatchId={swatch.id} handleChange={this.swatchUpdated} /></td>
          <td><SwatchAdjustmentDropdown swatchId={swatch.id}></SwatchAdjustmentDropdown></td>
          <td><Link to={`/swatches/${swatch.id}`}>Details</Link></td>
        </tr>
      )
    } else {
      listItems = [...Array(1)].map((e, i) => {return <tr key={i}><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td><td><Skeleton></Skeleton></td></tr>})
    }
    let mfgList = [<option value="">Any</option>]
    this.state.manufacturers.forEach((mfg) => {
      mfgList.push(<option value={mfg.id}>{mfg.attributes.name}</option>)
    })
    return(
      <section>
        <Row className="mb-3">
          <Col>
            <FormGroup>
              <Input type={'text'} placeholder="Search..." onChange={this.search}></Input>
            </FormGroup>
            <ButtonGroup>
              <Button outline color="secondary" size="sm" onClick={() => {this.toggleActiveFilter('')}} active={this.state.active === ''}>Any</Button>
              <Button outline color="secondary" size="sm" onClick={() => {this.toggleActiveFilter(true)}} active={this.state.active === true}>Active</Button>
              <Button outline color="secondary" size="sm" onClick={() => {this.toggleActiveFilter(false)}} active={this.state.active === false}>Inactive</Button>
            </ButtonGroup>{' '}
            <ButtonGroup>
              <Button outline color="secondary" size="sm" onClick={() => {this.toggleStockingFilter('')}} active={this.state.stocking === ''}>Any</Button>
              <Button outline color="secondary" size="sm" onClick={() => {this.toggleStockingFilter(true)}} active={this.state.stocking === true}>Stocked</Button>
              <Button outline color="secondary" size="sm" onClick={() => {this.toggleStockingFilter(false)}} active={this.state.stocking === false}>Not Stocked</Button>
            </ButtonGroup>{' '}
          </Col>
          <Col>
            <FormGroup>
              <Input type="select" onChange={this.filterMfg}>{mfgList}</Input>
            </FormGroup>
            <Button onClick={this.toggleModal} color="info">Add Swatch</Button><AddSwatchModal isOpen={this.state.modalOpen} toggle={this.toggleModal} handleCreate={this.swatchAdded} user={this.props.user} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table size={'sm'}>
              <thead><tr><th>Name</th><th>Status</th><th>Current Level</th><th>Velocity</th><th>Stock Remaining</th><th>Stocking</th><th></th><th></th></tr></thead>
              <tbody>{listItems}</tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Pagination
              activePage={this.state.currentPage}
              itemsCountPerPage={50}
              totalItemsCount={this.state.totalEntries}
              pageRangeDisplayed={5}
              itemClass="page-item"
              linkClass="page-link"
              onChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </section>
    )
  }
}

class AddSwatchModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {manufacturer: '', name: '', errorMsg: ''}
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  handleChange = (e) => {
    let state = this.state
    state[e.target.name] = e.target.value
    this.setState(state)
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.createSwatch()
  }
  createSwatch = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.createSwatch({
      swatch: {
        name: this.state.name,
        manufacturer_name: this.state.manufacturer
      }
    }, (status, headers, response) => {
      if (status === 201) {
        this.setState({successMsg: `${this.state.name} Created`})
        this.props.handleCreate(response.data)
      } else {
        let message = ''
        for (var prop in response) {
          message = prop + ' ' + response[prop]
          break;
        }
        this.setState({errorMsg: message})
      }
    })
  }
  render() {
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <Form onSubmit={this.handleSubmit}>
          <ModalHeader toggle={this.props.toggle}>Create Adjustment</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Manufacturer</Label>
                  <Input type={'text'} onChange={this.handleChange} name="manufacturer" value={this.state.manufacturer}></Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type={'text'} onChange={this.handleChange} name="name" value={this.state.name}></Input>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <p className="text-danger">{this.state.errorMsg}</p>
            <p className="text-success">{this.state.successMsg}</p>
            <Button color="primary" type={'submit'}>Create</Button>{' '}
            <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  { }
)(Swatches);