import React from 'react'
import { Button } from 'reactstrap'
import SwatchMonsterApi from '../services/SwatchMonsterApi'

export class SwatchActiveToggle extends React.Component {
  toggleActive = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.updateSwatch(this.props.swatchId, {swatch: {active: !this.props.active}}, (status, headers, response) => {
      if (status === 200) {
        if (this.props.handleChange !== undefined) { this.props.handleChange(response.data) }
      }
    })
  }
  render() {
    if (this.props.active) {
      return <Button color="success" size="sm" onClick={this.toggleActive}>Active</Button>
    } else {
      return <Button color="danger" size="sm" onClick={this.toggleActive}>Inactive</Button>
    }
  }
}

export class SwatchStockedToggle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {disabled: false}
  }
  toggleStocked = () => {
    this.setState({disabled: true})
    let api = new SwatchMonsterApi(this.props.user)
    api.updateSwatch(this.props.swatchId, {swatch: {stocked: !this.props.stocked}}, (status, headers, response) => {
      if (status === 200) {
        if (this.props.handleChange !== undefined) { this.props.handleChange(response.data) }
      }
      this.setState({disabled: false})
    })
  }
  render() {
    if (this.props.stocked) {
      return <Button color="success" size="sm" onClick={this.toggleStocked} disabled={this.state.disabled}>Stocked</Button>
    } else {
      return <Button color="danger" size="sm" onClick={this.toggleStocked} disabled={this.state.disabled}>Not Stocked</Button>
    }
  }
}
