import React from 'react'
import { Row, Col, Label, Input, Button, FormGroup } from 'reactstrap'
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import { ResponsiveBar } from '@nivo/bar'
import SwatchMonsterApi from '../../services/SwatchMonsterApi';

import { objToParam } from '../../utilities/objToParam'
import { dateList } from '../../utilities/dateList'

class RequestChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
      let data = this.props.chartData
      let keys = Object.keys(data[0]).filter((x) => x !== 'date')
      let tickValues = []
      if (data.length > 6) {
        for (let i = 0; i < data.length; (i += Math.round(data.length / 6))) {
          if (data[i] !== undefined) {tickValues.push(data[i].date)}
        } 
      } else {
        for (let i = 0; i < data.length; (i += Math.round(data.length / 1))) {
          if (data[i] !== undefined) {tickValues.push(data[i].date)}
        } 
      }
    return(
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="date"
        margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
        padding={0.1}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        isInteractive={false}
        axisBottom={{
            tickSize: 5,
            tickPadding: 0,
            tickRotation: 0,
            tickValues: tickValues,
            legend: 'date',
            legendPosition: 'middle',
            legendOffset: 82,
            format: (d) => d.toLocaleDateString('en-US')
        }}
        xScale={{ type: 'time' }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Amount',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        enableLabel={false}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 120,
                translateY: -50,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
    )
  }
}

export default class RequestVolume extends React.Component {
  constructor(props) {
    super(props)
    let currentDate = new Date()
    let date = new Date(new Date().setDate(new Date().getDate() - 30));
    this.state = {startDate: date, endDate: currentDate, swatchRequests: [], chartData: [{date: new Date(), Web: 1}], loading: true}
  }
  componentDidMount = () => {
    this.fetchSwatchRequests()
  }
  handleStartDateChange = (e) => {
    this.setState({startDate: e})
  }
  handleEndDateChange = (e) => {
    this.setState({endDate: e})
  }
  fetchSwatchRequests = () => {
    this.setState({loading: true})
    let api = new SwatchMonsterApi(this.props.user)
    let params = {
      q: {
        created_at_gt: this.state.startDate.toISOString(),
        created_at_lt: this.state.endDate.toISOString()
      }
    }
    api.getSwatchRequests(objToParam(params),(status, headers, response) => {
      if (status === 200) {
        let chartData = this.setData(response.data)
        this.setState({chartData: chartData, loading: false})
      } else {
        toast('')
      }
    })
  }
  setData = (swatchRequests) => {
    let nonPaddedData = {}
    let dataSetKeys = []
    let startDate = new Date()
    let endDate = new Date()
    swatchRequests.forEach((swatchRequest) => {
      if (startDate > swatchRequest.attributes.created_at) { startDate = swatchRequest.attributes.created_at }
      if (endDate < swatchRequest.attributes.created_at) { endDate = swatchRequest.attributes.created_at }
      let localizedDate = new Date(swatchRequest.attributes.created_at).toLocaleDateString()
      if (dataSetKeys.indexOf(swatchRequest.attributes.lead_owner) === -1 ) { dataSetKeys.push(swatchRequest.attributes.lead_owner)}
      if (nonPaddedData[localizedDate] === undefined) { nonPaddedData[localizedDate] = {}}
      if (nonPaddedData[localizedDate][swatchRequest.attributes.lead_owner] === undefined) {nonPaddedData[localizedDate][swatchRequest.attributes.lead_owner] = 0}
      nonPaddedData[localizedDate][swatchRequest.attributes.lead_owner] += 1
    })
    let dates = dateList(this.state.startDate, this.state.endDate)
    dates.forEach((date) => {
      let localizedDate = date.toLocaleDateString()
      if (nonPaddedData[localizedDate] === undefined) { nonPaddedData[localizedDate] = {} }
      dataSetKeys.forEach((key) => {
        if (nonPaddedData[localizedDate][key] === undefined) { nonPaddedData[localizedDate][key] = 0 }
      })
    })
    let dataArray = []
    for (let [date, result] of Object.entries(nonPaddedData)) {
      let dateObj = {date: new Date(date)}
      for (let [name, amount] of Object.entries(result)) {
        dateObj[name] = amount
      }
      dataArray.push(dateObj)
    }
    return dataArray
  }
  render() {
    return(
      <Row>
        <Col>
          <Row>
            <Col><p>Swatch request volume by who originated the order.</p></Col>
          </Row>
          <Row>
            <Col>
              <Label>Start Date</Label>
              <DatePicker selected={this.state.startDate} onChange={this.handleStartDateChange} className="form-control"></DatePicker>
            </Col>
            <Col>
              <Label>End Date</Label>
              <DatePicker selected={this.state.endDate} onChange={this.handleEndDateChange} className="form-control"></DatePicker>
            </Col>
            <Col>
              <Label>Brand</Label>
              <Input type="select"><option>All</option></Input>
            </Col>
            <Col>
              <Label>Salesperson</Label>
              <Input type="select"><option>All</option></Input>
            </Col>
          </Row>
          <Row>
            <Col><FormGroup><Button onClick={this.fetchSwatchRequests}>Update</Button></FormGroup></Col>
          </Row>
          <Row>
            <Col>
              <div style={{height: 500}}>
                <RequestChart chartData={this.state.chartData}></RequestChart>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}