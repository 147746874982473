import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import Auth from './components/Auth'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css"
import './App.css';

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Auth></Auth>
        <ToastContainer></ToastContainer>
      </Provider>
    );
  }
}

export default App;
