import React from 'react'
import Skeleton from 'react-loading-skeleton';
import Moment from 'react-moment';
import { connect } from 'react-redux'
import {
  Card, CardText, CardBody,
  CardTitle, Input, Form,
  Row, Col, Container,
  ListGroup, ListGroupItem
} from 'reactstrap';

import SwatchMonsterApi from '../services/SwatchMonsterApi'
import SwatchAdjustmentDropdown from './SwatchAdjustment'
import { toast } from 'react-toastify';

class EditableField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {value: '', editing: false, inputSize: '20ch'}
  }
  componentDidMount = () => {
    this.setState({value: this.props.value})
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.value !== this.props.value) { this.setState({value: this.props.value})}
  }
  toggleEdit = () => {
    this.setState({editing: !this.state.editing})
  }
  resizeInput = () => {
    this.setState({inputSize: `${this.state.value.length}ch`})
  }
  handleChange = (e) => {
    this.setState({value: e.target.value}, () => {this.resizeInput()})
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit(this.props.name, this.state.value)
    this.toggleEdit()
  }
  render() {
    if (this.state.editing) {
      return (
        <Form onSubmit={this.handleSubmit}>
          <Input value={this.state.value} style={{width: this.state.inputSize}} onChange={this.handleChange} className="editable"></Input>
        </Form>
      )
    } else {
      return <span onClick={this.toggleEdit}>{this.state.value}</span>
    }
  }
}
class Swatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id, 
      swatch: { attributes: {name: null, manufacturer_name: null, days_until_out: null}},
      adjustments: []
    }
  }
  componentDidMount() {
    this.fetchSwatch()
    this.fetchAdjustments()
  }
  fetchSwatch = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.getSwatch(this.state.id, (status, headers, response) => {
      if (status === 200) {
        this.setState({isLoaded: true, swatch: response.data})
      }
    })
  }
  updateSwatch = (name, value) => {
    let api = new SwatchMonsterApi(this.props.user)
    let swatch = this.state.swatch.attributes
    swatch[name] = value
    api.updateSwatch(this.state.id, {swatch: swatch}, (status, headers, response) => {
      if (status === 200) {
        toast('Swatch Updated')
        this.setState({swatch: response.data})
      } else {
        toast('API Error')
      }
    })
  }
  fetchAdjustments = () => {
    let api = new SwatchMonsterApi(this.props.user)
    api.getAdjustments(this.state.id, (status, headers, response) => {
      if (status === 200) {
        this.setState({isLoaded: true, adjustments: response.data})
      }
    })
  }
  handleAddAdjustment = (adjustment) => {
    let state = this.state
    state.adjustments.unshift(adjustment)
    this.setState(state)
    this.fetchSwatch()
  }
  render() {
    return(
      <Container>
        <section className="mb-4">
          <Row>
            <Col>
              <h1>
                <div><EditableField value={this.state.swatch.attributes.name} name="name" onSubmit={this.updateSwatch}></EditableField></div>
                <small>By {this.state.swatch.attributes.manufacturer_name}</small>
              </h1>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Current Level</CardTitle>
                  <CardText>{this.state.swatch.attributes.current_level || <Skeleton />}</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Velocity</CardTitle>
                  <CardText>{this.state.swatch.attributes.velocity || <Skeleton />}</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Days Until Out</CardTitle>
                  <CardText>{this.state.swatch.attributes.days_until_out || <Skeleton />}</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Total Orders</CardTitle>
                  <CardText>{this.state.swatch.attributes.total_orders || <Skeleton />}</CardText>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>Last Counted</CardTitle>
                  <CardText>{<Moment>{this.state.swatch.attributes.last_counted}</Moment> || <Skeleton />}</CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
        <Row>
          <Col>
            <SwatchActivity swatchId={this.state.id} adjustments={this.state.adjustments} handleAdd={this.handleAddAdjustment}></SwatchActivity>
          </Col>
        </Row>
      </Container>
    )
  }
}

class SwatchActivity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  handleCreate = (adjustment) => {
    this.props.handleAdd(adjustment)
  }
  render() {
    const listItems = this.props.adjustments.map((adjustment) =>
      <ListGroupItem key={adjustment.id}>
        <Row>
          <Col><Moment format="lll">{adjustment.attributes.created_at}</Moment></Col>
          <Col>{adjustment.attributes.type}</Col>
          <Col>{adjustment.attributes.amount}</Col>
        </Row>
      </ListGroupItem>
    );
    return(
      <div>
        <h2>Activity <SwatchAdjustmentDropdown swatchId={this.props.swatchId} handleCreate={this.handleCreate}></SwatchAdjustmentDropdown></h2>
        <ListGroup>{listItems}</ListGroup>
      </div>
    )
  }
}



function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(
  mapStateToProps,
  { }
)(Swatch);