import { SIGN_IN, SIGN_OUT } from "../ActionTypes";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN: {
      return signIn(state, action.payload)
    }
    case SIGN_OUT: {
      return signOut(state)
    }
    default:
      return state;
  }
}

function signIn(state, payload) {
  return Object.assign({}, state, payload) 
}

function signOut(state) {
  return {}
}